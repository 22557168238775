<style scoped></style>

<template>
  <fm-table-new
    :columns="columns"
    :auto-height="true"
    v-loadingx="loading"
    border="row"
    size="small"
    ref="table"
    :data-list="dataList"
    :stripe="false"
    emptyPlaceholder="-">
    <div slot="empty" style="margin: 30px 0;">
      <img src="/static/images/null.png" style="width: 40px;"/>
      <div>暂无数据</div>
    </div>
    <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
    <div slot="price" slot-scope="{ row }" style="width: 100px">
      <fm-input-number :value="row.price" :min="0" @input="(v) => setPrice(row, v)" />
    </div>
    <div slot="warningValue" slot-scope="{ row }" style="width: 100px">
      <fm-input-number :value="row.warningValue" :min="0" @input="(v) => setWarningValue(row, v)" />
    </div>
  </fm-table-new>
</template>

<script>
import { mainbodyMedicineRequest } from '@/api'
import TableActions from '@/components/base/TableActions'

let timer = null

export default {
  components: { TableActions },
  props: {
    loading: { type: Boolean, default: false },
    type: { type: String, default: null },
    dataList: { type: Array, default: () => ([]) },
  },
  computed: {
    tableActions () {
      return [
        { key: 'order', label: '台账' },
        { key: 'details', label: '明细' }
      ]
    },
    columns () {
      let columns = []

      columns = columns.concat([
        { title: '药品名称', field: 'medicineName', fixed: 'left' },
        { title: '药品类型', field: 'medicineType', fixed: 'left' },
        { title: '生产厂家', field: 'medicineManufacturer', fixed: 'left' },
        { title: '药品规格/规格', field: 'medicinePacking', fixed: 'left', width: 130 },
        { title: '平均采购价', field: 'avgPrice',
          render (h, row) {
            return h('div', row.avgPrice ? Number(row.avgPrice.toFixed(2)) : '-')
          }
        },
        { title: '零售价', field: 'price', slot: 'price', width: 100 },
        {
          title: '库存', field: 'totalVolume', render (h, row) {
            return h('div', row.inventory ? row.inventory.totalVolume : '-')
          },
        },
        { title: '库存预警值', field: 'warningValue', slot: 'warningValue', width: 100 },
        { title: '有效期', field: 'medicineShelfLife' },
        // { title: '状态', field: 'a7' }
      ])

      columns = columns.concat([
        { title: '操作', field: 'actions', slot: 'actions', fixed: 'right', search: false, export: false, configurable: false }
      ])

      return columns
    }
  },
  methods: {
    tableAction (e) {
      this.$emit('table-action', e)
    },
    reload () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.$emit('reload')
      }, 500);
    },
    async setPrice (r, v) {
      await mainbodyMedicineRequest.update(r.id, Object.assign(r, {price: v}))
      this.reload()
    },
    async setWarningValue (r, v) {
      await mainbodyMedicineRequest.update(r.id, Object.assign(r, {warningValue: v}))
      this.reload()
    }
  }
}
</script>
