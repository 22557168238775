<style scoped lang="less"></style>

<template>
  <PageBase
    title="药房管理"
    :page-actions="pageActions"
    @page-action="pageAction"
    @page-change="onPageChange"
    :filters="filters"
    @search="searchChange"
    :page-num="pageNum"
    :total="total"
    :page-size="pageSize"
    @page-size-change="onPageSizeChange"
  >
    <div style="height: 100%;display: flex;flex-direction: column;">
      <div style="display: flex;align-items: center;padding: 10px 0 10px 20px;">
        <div>总计概述</div>
        <div style="margin-left: 50px;display: flex; flex-direction: column; align-items: center;">
          <div style="font-size: 30px;color: red;">32039</div>
          <div>库存数量</div>
        </div>
        <div style="margin-left: 50px;display: flex; flex-direction: column; align-items: center;">
          <div style="font-size: 30px;color: red;">¥32039</div>
          <div>库存总成本</div>
        </div>
      </div>
      <div style="flex: 1;height: 0;position: relative;">
        <PharmacyTable style="height: 100%;" @table-action="tableAction" @reload="loadData" :data-list="dataList" />
      </div>
    </div>
    <template slot="modal">
      <fm-modal :value="status.form" title="新增药品" width="70vw" @cancel="status.form = false" :mask-closable="false">
        <PharmacyForm @submit="submit" v-if="status.form" />
      </fm-modal>
      <fm-modal :value="status.order" title="药品台账" width="70vw" @cancel="status.order = false" :mask-closable="false">
        <div style="height: 80vh;position: relative;">
          <PharmacyOrder v-if="status.order" :data="chooseData" />
        </div>
      </fm-modal>
      <fm-modal :value="status.details" title="药品库存明细" width="70vw" @cancel="status.details = false" :mask-closable="false">
        <PharmacyDetails @reload="loadData" v-if="status.details" :data="chooseData" />
      </fm-modal>
    </template>
  </PageBase>
</template>

<script>
import PageBase from '@/components/page/base'
import { mainbodyMedicineRequest } from '@/api'
import PharmacyForm from './form'
import PharmacyTable from './table'
import PharmacyOrder from './order'
import PharmacyDetails from './details'
import { types } from '@/views/medicine/lib'

export default {
  components: { PageBase, PharmacyForm, PharmacyTable, PharmacyOrder, PharmacyDetails },
  data () {
    return {
      status: {
        form: false,
        order: false,
        details: false
      },
      loading: {
        load: false
      },
      search: {},
      chooseData: null,
      datas: [],
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  computed: {
    filters () {
      return [
        { type: 'text', field: 'medicineName', label: '名称', show: true },
        {
          type: 'select', field: 'medicineType', label: '类型', show: true, filters: types.map(v => {
            return { label: v.label, value: v.value }
          })
        },
        { type: 'text', field: 'medicineManufacturer', label: '生产厂家' },
      ]
    },
    pageActions () {
      return [{value: 'add', label: '新增药品'}]
    },
    dataList () {
      return this.datas
    }
  },
  methods: {
    searchChange (data) {
      this.search = data
      this.loadData()
    },
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    pageAction (value) {
      if (value === 'add') {
        this.status.form = true
      }
    },
    tableAction ({action, data}) {
      this.chooseData = data
      if (action === 'order') {
        this.status.order = true
      } else if (action === 'details') {
        this.status.details = true
      }
    },
    async submit (data) {
      await mainbodyMedicineRequest.add(data)
      this.$notice.success('添加成功')
      this.loadData()
      this.status.form = false
    },
    async loadData () {
      this.loading.load = true
      const res = await mainbodyMedicineRequest.getBase(Object.assign({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }, this.search))
      this.datas = res.data
      this.total = res.total
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData() 
  }
}
</script>
