<style scoped lang="less">
  .btns {
    margin-top: 20px;
    justify-content: space-around;
    display: flex;
  }
</style>

<template>
  <div>
    <fm-form>
      <fm-form-item label="数量">
        <fm-input-number style="width: 100%;" v-model="outData.volume" :min="0" />
      </fm-form-item>
    </fm-form>
    <div slot="footer" class="btns">
      <fm-btn @click="submit">出库</fm-btn>
      <fm-btn @click="$emit('close')">取消</fm-btn>
    </div>
  </div>
</template>

<script>
import { inventoryDetailRequest } from '@/api'
export default {
  props: {
    data: { type: Object, default: null },
    details: { type: Object, default: null }
  },
  data () {
    return {
      outData: {
        volume: null
      }
    }
  },
  methods: {
    async submit () {
      const data = Object.assign({
        type: 'out'
      }, {
        medicineId: this.details.medicineId,
        productionBatch: this.details.productionBatch,
        productionDate: this.details.productionDate,
        expiredDate: this.details.expiredDate,
        volume: this.outData.volume
      })
      await inventoryDetailRequest.add(data)
      this.$notice.success({title: '出库成功'})
      this.$emit('success', data)
    }
  },
}
</script>
