<style lang="less">
  .pharmacy-order {
    padding: 0 !important;
    height: 100%;
    .container {
      .content {padding: 0 !important;margin: 0 !important;}
    }
  }
</style>

<template>
  <PageBase
    class="pharmacy-order"
    :page-num="pageNum"
    :total="total"
    :page-size="pageSize"
    @page-change="onPageChange"
    @page-size-change="onPageSizeChange"
    :tableColumns="columns"
    :dataList="dataList"
    :filters="filters"
    @search="searchChange"
    :loading="loading"
  >
  </PageBase>
</template>

<script>
import PageBase from '@/components/page/base'
import { inventoryDetailRequest } from '@/api'

export default {
  components: { PageBase },
  props: {
    data: { type: Object, default: null },
  },
  data () {
    return {
      loading: false,
      datas: [],
      search: {},
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.pageNum = 1
        this.total = 0
        this.loadData()
      }
    }
  },
  computed: {
    filters () {
      return []
    },
    dataList () {
      return this.datas
    },
    columns () {
      let columns = []
      let vm = this
      columns = columns.concat([
        { title: '药品名称', field: 'medicineName' },
        { title: '生产批号', field: 'productionBatch' },
        {
          title: '操作', field: 'type', render (h, row) {
            return h('div', {
              style: {
                color: row.type === 'in' ? 'green' : 'red'
              }
            }, vm.getTypeText(row))
          }
        },
        { title: '数量', field: 'volume' },
        { title: '采购价', field: 'price' },
        // { title: '入库批号', field: 'a2' },
        { title: '操作人', field: 'userName' },
        { title: '操作时间', field: 'createTime' }
      ])

      return columns
    }
  },
  methods: {
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    searchChange (data) {
      this.search = data
    },
    getTypeText (data) {
      let text = data.type === 'in' ? '入库' : '出库'
      if (data.sourceType === 'delivery_order_detail') {
        text = '采购' + text
      } else if (data.sourceType === 'order_detail') {
        text = '处方售药'
      }
      return text
    },
    async loadData () {
      this.loading = true
      let pager = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      let res = null
      if (this.data.inventoryId) {
        res = await inventoryDetailRequest.get(Object.assign({
          inventoryId: this.data.inventoryId
        }, pager))
      } else {
        res = await inventoryDetailRequest.get(Object.assign({
          mainbodyId: this.data.mainbodyId,
          medicineId: this.data.medicineId
        }, pager))
      }
      this.datas = res.data
      this.total = res.total
      this.loading = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
