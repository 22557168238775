var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fm-table-new',{directives:[{name:"loadingx",rawName:"v-loadingx",value:(_vm.loading),expression:"loading"}],ref:"table",attrs:{"columns":_vm.columns,"auto-height":true,"border":"row","size":"small","data-list":_vm.dataList,"stripe":false,"emptyPlaceholder":"-"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var row = ref.row;
var index = ref.index;
return _c('table-actions',{attrs:{"data":_vm.tableActions,"index":index,"row":row},on:{"table-action":_vm.tableAction}})}},{key:"price",fn:function(ref){
var row = ref.row;
return _c('div',{staticStyle:{"width":"100px"}},[_c('fm-input-number',{attrs:{"value":row.price,"min":0},on:{"input":function (v) { return _vm.setPrice(row, v); }}})],1)}},{key:"warningValue",fn:function(ref){
var row = ref.row;
return _c('div',{staticStyle:{"width":"100px"}},[_c('fm-input-number',{attrs:{"value":row.warningValue,"min":0},on:{"input":function (v) { return _vm.setWarningValue(row, v); }}})],1)}}])},[_c('div',{staticStyle:{"margin":"30px 0"},attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"width":"40px"},attrs:{"src":"/static/images/null.png"}}),_c('div',[_vm._v("暂无数据")])])])}
var staticRenderFns = []

export { render, staticRenderFns }