<style scoped></style>

<template>
  <div>
    <fm-form inline>
      <fm-form-item label="药品编号:">{{medicine.code}}</fm-form-item>
      <fm-form-item label="药品名称:">{{medicine.name}}</fm-form-item>
      <fm-form-item label="药品类型:">{{medicine.type}}</fm-form-item>
      <fm-form-item label="生产厂家:">{{medicine.manufacturer}}</fm-form-item>
      <fm-form-item :label="getPackingName(medicine.type) + ':'">{{medicine.packing}}</fm-form-item>
      <fm-form-item label="保质期:">{{medicine.shelfLife}}</fm-form-item>
    </fm-form>

    <fm-title titleText="库存信息" @clickTitleMenu="clickTitleMenu" :titleMenus="[{label: '台账', key: 'order'}, {label: '入库', key: 'put'}]"></fm-title>
    
    <div style="height: 300px;position: relative;">
      <fm-table-new
        :columns="columns"
        auto-height
        v-loadingx="loading.load"
        border="row"
        size="small"
        ref="table"
        :data-list="dataList"
        :stripe="false"
        emptyPlaceholder="-">
        <div slot="empty" style="margin: 30px 0;">
          <img src="/static/images/null.png" style="width: 40px;"/>
          <div>暂无数据</div>
        </div>
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
      </fm-table-new>
    </div>

    <fm-page
      :current="pageNum"
      :total="total"
      :page-size="pageSize"
      @page-change="onPageChange"
      @page-size-change="onPageSizeChange"
      show-total
      show-sizer
      show-elevator
      style="padding: 0 20px;"
    />

    <fm-modal :value="status.put" title="入库" width="400px" @cancel="status.put = false" :mask-closable="false">
      <PharmacyPut v-if="status.put" @success="loadData();$emit('reload');status.put = false" @close="status.put = false" :data="data" />
    </fm-modal>

    <fm-modal :value="status.out" title="出库" width="300px" @cancel="status.out = false" :mask-closable="false">
      <PharmacyOut v-if="status.out" @success="loadData();$emit('reload');status.out = false" @close="status.out = false" :data="data" :details="chooseData" />
    </fm-modal>

    <fm-modal :value="status.order" title="药品台账" width="70vw" @cancel="status.order = false" :mask-closable="false">
      <div style="height: 80vh;position: relative;">
        <PharmacyOrder v-if="status.order" :data="data" />
      </div>
    </fm-modal>
  </div>
</template>

<script>
import { medicineRequest, inventoryRequest } from '@/api'
import TableActions from '@/components/base/TableActions'
import PharmacyOrder from './order'
import PharmacyPut from './put'
import PharmacyOut from './out'
import { getPackingName } from '@/views/medicine/lib'

export default {
  components: { TableActions, PharmacyOrder, PharmacyPut, PharmacyOut },
  props: {
    type: { type: String, default: null },
    data: { type: Object, default: null }
  },
  data () {
    return {
      medicine: {},
      datas: [],
      loading: {
        load: false
      },
      status: {
        order: false,
        put: false,
        out: false
      },
      chooseData: null,
      pageNum: 1,
      total: 0,
      pageSize: 10
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.pageNum = 1
        this.loadData()
      }
    }
  },
  computed: {
    getPackingName () {
      return getPackingName
    },
    dataList () {
      return this.datas
    },
    tableActions () {
      return [
        { label: '出库', key: 'out' }
      ]
    },
    columns () {
      let columns = []

      columns = columns.concat([
        {
          title: '生产日期', field: 'productionDate', render (h, row) {
            return h('div', row.productionDate ? row.productionDate.substr(0, 10) : '-')
          }
        },
        { title: '生产批号', field: 'productionBatch'},
        { title: '采购价', field: 'price' },
        { title: '库存数量', field: 'volume' },
        { title: '药品状态', field: 'a2' }
      ])
      columns = columns.concat([
        { title: '操作', field: 'actions', width: 100, slot: 'actions', fixed: 'right', search: false, export: false, configurable: false }
      ])

      return columns
    }
  },
  methods: {
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    tableAction ({action, data}) {
      this.chooseData = data
      if (action === 'out') {
        this.status.out = true
      }
    },
    clickTitleMenu (key) {
      if (key === 'put') {
        this.status.put = true
      } else if (key === 'order') {
        this.status.order = true
      }
    },
    async loadData () {
      this.loading.load = true
      if (this.data.medicineId) {
        const res = await medicineRequest.get({id: this.data.medicineId})
        if (res.length) {
          this.medicine = res[0]
        }
      }
      const res = await inventoryRequest.get({
        medicineId: this.data.medicineId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      })
      this.datas = res.data
      this.total = res.total
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
